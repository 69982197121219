<template>
  <v-card outlined v-if="team">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" >
          <div :style="'float:left; height: 40px; width: 40px; border-radius: 50%; border: solid 6px green;display: inline-block;'">&nbsp;</div>
        </v-col>
        <v-col cols="12" class="pa-2 pl-0">
          <h2>{{headline}}</h2>
          <div v-html="content" class="mt-2" />
        </v-col>
        <v-col cols="12">
           <v-slide-group show-arrows="desktop">
            <v-slide-item v-for="(member,index) in team" :key="index" :style="'width: ' + teamImageWidth">
              <div class="ma-1 text-wrap">
                <v-img class="rounded" v-if="member.image" :src="'https://directus.offenestadt.info/assets/' + member.image.id" :title="member.image.title" contain/>
                <h3 class="my-2">{{member.name}} | {{member.title}}</h3>
                <div v-html="member.description" style="height:340px" />
                <v-img :src="'https://directus.offenestadt.info/assets/' + member.logo.id" max-height="100px" max-width="300px" :title="member.logo.title" contain/>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'TeamCard',
  props: ['headline','content'],
  data: () => ({
    team: null
  }),  
  async created() {
    let temp = await this.$client.items("team").readMany({ fields: ['*.*']})
    this.team = temp.data
  },
  computed: {
      teamImageWidth () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '220px'
          /*case 'sm': return '400px'
          case 'md': return '400px'
          case 'lg': return '400px'
          case 'xl': return '400px'*/
        }
        return '400px'
      },
    }
}
</script>

