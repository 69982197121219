<template>
  <div v-if="about_us">
    <v-container fluid class="pt-0 px-0">
     <v-img v-if="about_us.header_image" :src="'https://directus.offenestadt.info/assets/' + about_us.header_image.id" style="margin:auto" max-height="400px">
        <v-container class="white--text fill-height">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <ColorCircle color="white" />
            </v-col>
            <v-col cols="12" md="6">
              <h1>Über uns</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-container>

    <v-container v-if="about_us" id="aboutus" class="pa-0 my-4">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12">
          <TeamCard :headline="about_us.box_1_headline" :content="about_us.box_1_content" />
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ColorCircle from '@/components/ColorCircle.vue'
import TeamCard from '@/components/TeamCard.vue'

export default {
  name: 'AboutUs',
  components: {
    ColorCircle,
    TeamCard
  },
  data: () => ({
    about_us:null
  }),
  async created() {
    let temp = await this.$client.items("about_us").readMany({ fields: ['*.*']})
    this.about_us = temp.data
  }
}
</script>