<template>
  <div v-if="review">
    <v-container fluid class="pt-0 px-0">
      <v-img v-if="review.header_image" style="margin:auto;" :src="'https://directus.offenestadt.info/assets/' + review.header_image.id" max-height="725px">
        <v-container class="white--text fill-height">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <ColorCircle color="white" />
            </v-col>
            <v-col cols="12" md="6">
              <div v-html="review.header_content" style="clear:both;" />
            </v-col>
            <v-col cols="12" md="12">
              <v-btn href="https://www.youtube.com/watch?v=lTZJKKqJK9o&list=PL21YiU8i5e4HB2jdErDNortpvQsnEAtJu" target="_blank">zur Youtube-Mediathek</v-btn>
            </v-col>
            <!--<v-col cols="12" md="6">
              <v-img class="my-xs-1 my-sm-4 display-inline-block" style="margin:auto;" src="../assets/logos/CodeForHamburg.svg" max-height="90px" width="200px" contain />
              <v-img class="my-xs-1 my-sm-4 display-inline-block" style="margin:auto;" src="../assets/logos/Logo_Koerber-Stiftung_weiss.png" width="200px" contain />
            </v-col>-->
            <!--<v-col cols="12" class="pt-4">
              <v-btn color="#A1C2E6" href="https://www.koerber-stiftung.de/?id=2393" target="_blank" class="white--text ma-1">Jetzt teilnehmen</v-btn>
              <v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text ma-1">Programm öffnen</v-btn>
            </v-col>-->
          </v-row>
        </v-container>
      </v-img>
    </v-container>

    <v-container v-if="review" class="pa-0 my-8">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12" sm="12" md="12">
          <ContentCard card-color="white" circle-color="#1F446C" font-color="black" :headline="review.box_1_headline" :content="review.box_1_content" :image="review.box_1_image" image-position="right" />
          <!--<div style="width:200px;margin: auto">
            <v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text">Programm öffnen</v-btn>
          </div>-->
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="review" class="pa-0 my-8">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12" sm="12" md="12">
          <ContentCard card-color="white" circle-color="#1F446C" font-color="black" :headline="review.box_2_headline" :content="review.box_2_content" :image="review.box_2_image"  image-position="left"/>
          <!--<div style="width:200px;margin: auto">
            <v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text">Programm öffnen</v-btn>
          </div>-->
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="review" class="pa-0 my-8">
      <v-row justify="space-around" class="ma-0">
        <v-col cols="12" sm="12" md="12">
          <ContentCard card-color="white" circle-color="#1F446C" font-color="black" :headline="review.box_3_headline" :content="review.box_3_content" :image="review.box_3_image"  image-position="right"/>
          <!--<div style="width:200px;margin: auto">
            <v-btn color="#A1C2E6"  href="/Programm%20FOS%202021.pdf" target="_blank" class="white--text">Programm öffnen</v-btn>
          </div>-->
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import ContentCard from '@/components/ContentCard.vue'
import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'Review',
  components: {
    ContentCard,
    ColorCircle
  },
  data: () => ({
    review:null,
    event: null,
    displayZoomButtons: false
  }),
  async created() {
    let temp = await this.$client.items("review").readMany({ fields: ['*.*']})
    this.review = temp.data
  }
}
</script>

<style lang="scss">

.display-inline-block {
  display: inline-block
}

</style>
